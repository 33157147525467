const baseUrlAndType = getBaseUrlAndType();

let BASE_URL = baseUrlAndType.baseUrl;
export const MIN = baseUrlAndType.min;
export const BROWSER = getBrowser();
export const IS_SUPPORTED_BROWSER = isSupportedBrowser(BROWSER);

export function load(_deps, buffered) {
	const deps = _deps.map(makeLoadFn);
	let loading = deps;

	// check for standalone or online…
	if (window.pagestripStandalone) {
		const onlineImage = new Image();
		onlineImage.onerror = function () {
			// load relative
			next();
		};
		onlineImage.onload = function () {
			// load absolute online
			BASE_URL = 'https://pagestrip-js.s3.amazonaws.com/player/production/build/';
			next();
		};
		onlineImage.src = `http://pagestrip-static.s3.amazonaws.com/img/transparent.png?r=${Date.now()}`;
	} else {
		next();
	}

	function next() {
		if (!loading.length) {
			loading = buffered;
		}
		if (!loading.length) { return; }
		const fn = loading.shift();
		fn(next);
	}
}

function injectJS(url, {async = false, importance = "auto"}, next) {
	const el = document.createElement('script');
	el.setAttribute("charset", "utf-8");
	el.src = url;
	if (async) {
		el.async = true;
	} else {
		el.onload = next;
	}
	el.setAttribute("importance", importance);
	document.head.appendChild(el);
	if (async) {
		next();
	}
}

function injectCSS(url, options, next) {
	const el = document.createElement("link");
	const hasOnload = "onload" in el;
	el.rel = "stylesheet";
	el.href = url;
	el.setAttribute(
		"importance",
		options && options.importance ? options.importance : "auto"
	);	
	if (hasOnload) {
		el.onload = next;
	}
	document.head.appendChild(el);
	if (!hasOnload) {
		next();
	}
}

function makeLoadFn(options) {
	if (Array.isArray(options)) {
		return next => loadParallel(options, next);
	}
	const {name, url, nonlocal, sanity} = options;
	const realUrl = absoluteUrl(url, nonlocal);
	return next => {
		const callNext = () => {
			if ((sanity && !sanity())) {
				const msg = `loading ${name} failed`;
				console.error(msg);
				if (window.Sentry) {
					window.Sentry.withScope(scope => {
						scope.setTags({logger: 'loader'});
						scope.setLevel('error');
						scope.setExtras({url: realUrl});
						window.Sentry.captureMessage(msg);
					});
				}
			}
			next();
		};
		if (options.isCss) {
			injectCSS(realUrl, options, callNext);
		} else {
			injectJS(realUrl, options, callNext);
		}
	};
}

function loadParallel(opts, next) {
	let len = opts.length;
	if (len === 0) {
		next();
		return;
	}
	opts.forEach(o => makeLoadFn(o)(parNext));
	function parNext() {
		if (--len === 0) {
			next();
		}
	}
}

function absoluteUrl(url, nonlocal = false) {
	if (url.indexOf('./') === 0) {
		return BASE_URL + url.slice(2);
	}
	if (url.indexOf('//') === 0) {
		let proto = document.location.protocol;
		if (nonlocal && proto === 'file:') {
			// in case we come from file:, force all external scripts to https:
			proto = 'https:';
		}
		return proto + url;
	}
	return url;
}

function getBaseUrlAndType() {
	let baseUrl;
	if (document.currentScript) {
		baseUrl = document.currentScript.src;
	} else {
		const rgx = new RegExp("\/(cockpit-)?loader(\.min)?\.js");
		const scripts = document.getElementsByTagName('script');
		let i = scripts.length - 1;
		do {
			baseUrl = scripts[i].src;
			i--;
		} while(!baseUrl.match(rgx) && i >= 0);
	}
	const minstr = '.min.js';
	const path = baseUrl.split('?')[0] // if there's a query string
	let min = path.slice(path.length - minstr.length) === minstr
		? '.min' : '';
	baseUrl = baseUrl.replace(/\/[^/]*$/, '/');
	const branch = (window.location.search.slice(1).split('&').map(p => p.split('=')).filter(p => p[0] === 'player_branch')[0] || [])[1];
	if (branch) {
		baseUrl = baseUrl.replace('js.pagestrip.com', `j.pagestrip.com/player/${branch}/compressed/build`);
		baseUrl = baseUrl.replace('/master/', `/${branch}/`);
	}
	const playerDebug = !!((window.location.search.slice(1).split('&').map(p => p.split('=')).filter(p => p[0] === 'player_debug')[0] || []).length);
	if (playerDebug) {
		min = '';
	}
	return {baseUrl, min};
}

export function isSupportedBrowser(browser, minVersion = {}) {
	var defaultMinVersion = {ie: 9, safari: 7, chrome: 20, firefox: 20, opera: 15, android: '4.4.4'};
	for (var k in minVersion) {
		defaultMinVersion[k] = minVersion[k];
	}

	minVersion = defaultMinVersion;

	// the browser is supported if it is not one of the following
	return !(
		browser.name === 'MSIE' && browser.version <= minVersion.ie ||
		browser.name === 'Safari' && browser.version <= minVersion.safari ||
		browser.name === 'Chrome' && browser.version <= minVersion.chrome ||
		browser.name === 'Firefox' && browser.version <= minVersion.firefox ||
		browser.name === 'Opera' && browser.version <= minVersion.opera ||
		browser.name === 'Android' && browser.version < minVersion.android
	);
}

// powered by: http://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
function getBrowser() {
	const ua = navigator.userAgent;
	let tem;
	if (ua.match(/(bot|crawler|spider)/i)) {
		return {
			name: 'robot',
			version: 1,
		};
	}
	let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return {
			name: 'MSIE',
			version: tem[1] || 0,
		};
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
		if (tem) {
			tem = tem.slice(1);
			return {
				name: tem[0].replace('OPR', 'Opera'),
				version: tem[1],
			};
		}
	} else if (M[1] === 'Safari' && ua.indexOf('Android') !== -1) {
		return {
			name: 'Android',
			version: ua.match(/android ([0-9\.]+)/i)[1],
		};
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if (M[0] !== 'Chrome' && (tem = ua.match(/version\/(\d+)/i))) {
		M.splice(1, 1, tem[1]);
	}
	return {
		name: M[0],
		version: Number(M[1]),
	};
}
