export default function showNotification(browser) {
	const notification = document.createElement('div');
	notification.className = 'pagestrip-player-loader-notification';
	const ns = notification.style;
	ns.position = 'fixed';
	ns.top = ns.left = ns.right = ns.bottom = '0px';
	ns.zIndex = 100;

	notification.innerHTML = `
		<div class="pagestrip-player-loader-notification-bg" style="opacity: 0.8; background-color: #000000; position: absolute; top:0; left:0; right:0; bottom:0; z-index: 1;"></div>
		<div class="pagestrip-player-loader-notification-msg" style="width: 80%; max-width: 300px; height: auto; text-align: center; padding: 30px; background-color: #ffffff; margin: 0 auto; position: absolute; left:0; right:0; top: 40%; z-index: 2; font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, Sans-Serif">
			<div style="line-height: 1.4em;">
				We are sorry, but your browser (${browser.name} ${browser.version}) isn't supported.
			</div>
			<div style="margin-top: 20px;">
				<a href="#leave" onclick="javascript:window.history.back(); return false;" style="display: inline-block; text-decoration: none; background-color: #000000; color: #ffffff; padding: 5px 10px;">&laquo; go back</a>
			</div>
		</div>
	`;

	window.onload = () => {
		document.body.appendChild(notification);
	};
}
