import {MIN, BROWSER, IS_SUPPORTED_BROWSER, load} from './helpers.js';
import showNotification from './notification.js';

const POLYFILLS = [];
// check for Array#includes from ES2016
// also check Reflect, Symbol from ES2015
// also check for Object.assign as that appears in some Sentry errors
// and iterator in NodeList, which apparently does not exist in some browsers
const NEEDS_CORE = () => typeof Array.prototype.includes === 'undefined' ||
	typeof Reflect === 'undefined' ||
	typeof Symbol === 'undefined' ||
	typeof Object.assign === 'undefined' ||
	!NodeList.prototype[Symbol.iterator];
if (NEEDS_CORE()) {
	POLYFILLS.push({name: "core-js", url: `./cached/core${MIN}.js?hash=__HASH__`, sanity: () => !NEEDS_CORE(), importance: "high"});
}
// test for intl and include the polyfill
const NEEDS_INTL = () => typeof Intl === 'undefined';
if (NEEDS_INTL()) {
	POLYFILLS.push({name: "intl", url: './cached/intl.js?hash=__HASH__', sanity: () => !NEEDS_INTL(), importance: "high"});
}

let USE_ES;
try {
	// check for async functions and trailing commas, we assume that all the
	// browsers that support those two features are new enough to also support
	// all the other great stuff :-)
	new Function("(async function a() { await a; })(a**2,)");
	USE_ES = true;
} catch (e) {
	USE_ES = false;
}

//XXX: this disables ES for Safari 10 for the time being since there seems to be a bug with babel-minify
//@see: https://github.com/babel/minify/issues/681
if (USE_ES && (~navigator.appVersion.indexOf('Safari') && ~navigator.appVersion.indexOf('Version/10.') || !~navigator.userAgent.indexOf('Safari') && ~navigator.userAgent.indexOf('AppleWebKit'))) {
	USE_ES = false;
}
if (window.Promise) {
	//Some browsers support Promises but not finally
	Promise.prototype.finally = Promise.prototype.finally || {
		finally (fn) {
		const onFinally = callback => Promise.resolve(fn()).then(callback);
		return this.then(
			result => onFinally(() => result),
			reason => onFinally(() => Promise.reject(reason))
		);
		}
	}.finally;
}

const PLAYER_URL = USE_ES
	? `./cached/kent.es${MIN}.js?hash=__HASH__`
	: `./cached/kent${MIN}.js?hash=__HASH__`;

const DEPS = [
	POLYFILLS,
	// our own player stuff
	{name: "css", url: `./cached/kent${MIN}.css?hash=__HASH__`, isCss: true, importance: "high"},
	{name: "player", url: PLAYER_URL, sanity: () => window.Kent.renderClient !== renderClient, importance: "high"},
];

const buffered = [];
export function renderClient() {
	const args = arguments;

	if (args[1] && args[1].jackInTheBox) {
		//display a loader
		const loadingStyles = document.createElement('style');
		loadingStyles.innerHTML = `
			@keyframes ps-loading-spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}

			.ps-loading-indicator {
				width: 100%;
				height: 100vh;
				opacity: 0.4;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.ps-loading-spinner {
				width: 80px;
				height: 80px;
				border: 8px solid rgba(0, 0, 0, 0.2);
				border-left-color: black;
				animation: ps-loading-spin 1.1s infinite linear;
				border-radius: 50%;
			}
		`;
		const loadingIndicator = document.createElement('div');
		loadingIndicator.className = 'ps-loading-indicator ps-loading-indicator-custom';
		const spinner = document.createElement('div');
		spinner.className = 'ps-loading-spinner ps-loading-spinner-custom';

		loadingIndicator.appendChild(spinner);
		args[2].appendChild(loadingStyles);
		args[2].appendChild(loadingIndicator);

		try {
			var ga = window['GoogleAnalyticsObject'] || 'ga';
			window[ga] = window[ga] || function(){
				(window[ga].q=window[ga].q||[]).push(arguments);
				if (arguments[0] === 'create') {
					window[ga](function() { 
						var tracker = window[ga].getAll()[window[ga].getAll().length - 1]; 
						tracker.set('page', window.location.href.substring(window.location.origin.length)); 
					});
				}
			};
			window[ga].l = 1 * new Date();
			window[ga]["ps_f"] = true; /* mark the stand-in GA tracker as our own */

			window['dataLayer'] = window['dataLayer'] || [];
			window['dataLayer'].push({"pagePath": window.location.href.substring(window.location.origin.length)});
		} catch(e) { console.warn(e) }
	}

	buffered.push(next => {
		const client = window.Kent.renderClient;
		if (client !== renderClient) {
			client.apply(null, args);
		}
		next();
	});
}

if (IS_SUPPORTED_BROWSER) {
	// quick check for recent chrome, since google pagespeed insights uses it
	
	if (!window.psHasWebPSupport) {
		const m = window.navigator.userAgent.match(/Chrome\/([0-9]+)/);
		window.psHasWebPSupport = m && m.length > 1 && parseInt(m[1]) >= 83;
	}
	//if still no webp support
	//webp - based on https://stackoverflow.com/a/27232658 + https://stackoverflow.com/a/54120785
	if(!window.psHasWebPSupport) {
		const webPCanvas = document.createElement('canvas');
		if (!!(webPCanvas.getContext && webPCanvas.getContext('2d'))) {		
			//this will only work properly in chrome
			window.psHasWebPSupport = webPCanvas.toDataURL('image/webp').indexOf('data:image/webp') == 0;
		}
	}
	if(!window.psHasWebPSupport) {
		const webP = new Image();
		webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
		webP.onload = webP.onerror = () => {
			window.psHasWebPSupport = (webP.height === 2);
		};
	}

	if (!window.psHasAVIFSupport) {
		const m = navigator.userAgent.indexOf("Edg/") < 0 && window.navigator.userAgent.match(/Chrome\/([0-9]+)/);
		window.psHasAVIFSupport = m && m.length > 1 && parseInt(m[1]) >= 89;

		if (!window.psHasAVIFSupport) {
			const avif = new Image();
			avif.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
			avif.onload = avif.onerror = () => {
				window.psHasAVIFSupport = (avif.height === 2);
			}
		}
	}

	if (!window.psHasWOFF2Support) {
		const m = navigator.userAgent.indexOf("Edg/") < 0 && window.navigator.userAgent.match(/Chrome\/([0-9]+)/);
		  
		window.psHasWOFF2Support = m && m.length > 1 && parseInt(m[1], 10) >= 36;
		  
		if (!window.psHasWOFF2Support && typeof window !== "undefined" && ("FontFace" in window)) {
			const testFont = new FontFace(
				"t",
				'url( "data:font/woff2;base64,d09GMgABAAAAAADwAAoAAAAAAiQAAACoAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAABmAALAogOAE2AiQDBgsGAAQgBSAHIBuDAciO1EZ3I/mL5/+5/rfPnTt9/9Qa8H4cUUZxaRbh36LiKJoVh61XGzw6ufkpoeZBW4KphwFYIJGHB4LAY4hby++gW+6N1EN94I49v86yCpUdYgqeZrOWN34CMQg2tAmthdli0eePIwAKNIIRS4AGZFzdX9lbBUAQlm//f262/61o8PlYO/D1/X4FrWFFgdCQD9DpGJSxmFyjOAGUU4P0qigcNb82GAAA" ) format( "woff2" )',
				{}
			  );
	
			  testFont.display = "swap";
			  testFont.load().catch(() => 0);
	
			  window.psHasWOFF2Support = ["loading", "loaded"].includes(testFont.status);
		}
	}

	load(DEPS, buffered);
} else {
	showNotification(BROWSER);
}
